.gform_wrapper ul {
  @extend .list-unstyled;
}
.gform_wrapper li {
  @extend .form-group;
}
.gform_wrapper form {
  margin-bottom: 0;
}
.gform_wrapper .gfield_required {
  padding-left: 1px;
  color: $state-danger-text;
}
.ginput_container input,
.ginput_container select,
.ginput_container textarea {
	@extend .form-control;
}
.ginput_container textarea {
  height: auto;
}
.gform_button {
	@extend .btn;
	@extend .btn-primary;
}
.gform_wrapper .gfield_error {
  .gfield_label {
    color: $state-danger-text;
  }
  input,
  select,
  textarea {
    border-color: $alert-danger-border;
    background-color: $alert-danger-bg;
    color: $alert-danger-text;
    @include form-control-focus($alert-danger-text);
  }
}
.validation_error {
	@extend .alert;
	@extend .alert-danger;
}
#gforms_confirmation_message {
	@extend .alert;
	@extend .alert-success;
}

/* Overrides */
.ginput_container textarea {
  border: 1px solid #e1e1e1;
  border-radius: 0;
  box-shadow: none;
  color: #000;
  &:focus {
    border-color: #e1e1e1;
    box-shadow: none;
  }
}

.gform_footer {
  margin-top: 25px;
}

@media only screen and (min-width: $screen-sm-min) {
  .gform_wrapper {
    .ginput_container {
      float: right;
      width: 70%;
    }
    .gform_footer {
      clear: both;
      float: right;
    }
  }
}
