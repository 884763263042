/*********************
01. GENERAL STYLES
*********************/

body {
	padding-top: 41px;
	&.logged-in {
		#header .navbar {
			top: 46px;
		}
		#wpadminbar {
			position: fixed;
		}
	}
	@media only screen and (min-width: $screen-sm-min) {
		padding: 0;
		&.logged-in #header .navbar {
			top: 0;
		}
	}
}

img {
	max-width: 100%;
	height: auto;
}

p {
	text-align: justify;
}

form, .comment-form {
	label {
		color: $brand-primary;
		margin: 0;
	}
	input[type=text], input[type=email], input[type=search], input[type=url], input[type=file], textarea {
		border: none;
		border-bottom: 1px solid #e1e1e1;
		border-radius: 0;
		box-shadow: none;
		color: #000;
		height: 24px;
		padding: 0 12px;
		&:focus {
			border-color: #e1e1e1;
			box-shadow: none;
		}
	}
	textarea {
	 	border: 1px solid #e1e1e1;
	  	border-radius: 0;
	  	box-shadow: none;
	  	color: #000;
	  	height: auto;
	  	margin-top: 10px;
	  	&:focus {
	    	border-color: #e1e1e1;
	    	box-shadow: none;
	  	}
	}
	input[type=checkbox], input[type=radio] {
		visibility: hidden;
		width: auto;
		height: auto;
		&:not(:checked) + label,
		&:checked + label {
			display: inline-block;
			margin-left: 30px;
			&:before {
				content: '';
				background-color: #fff;
				border: 1px solid $brand-primary;
				@include size(20px,20px);
				position: absolute;
				left: 0;
				top: 14px;
			}
			&:after {
				content: '\f00c';
				font-family: FontAwesome;
				font-size: 14px;
				color: #ff0000;
				position: absolute;
				left: 3px;
				top: 14px;
				opacity: 1;
			}
		}
		&:not(:checked) + label:after {
			opacity: 0;
		}
	}
	input[type=radio] {
		&:not(:checked) + label,
		&:checked + label {
			&:before {
				border-radius: 50%;
			}
			&:after {
				content: '';
				background-color: #ff0000;
				border-radius: 50%;
				@include size(14px,14px);
				top: 17px;
				left: 3px;
			}
		}
	}
	input[type=submit] {
		border: none;
		border-radius: 0;
		&:active {
			box-shadow: none;
		}
		&:hover, &:focus {
			background-color: #000;
		}
	}
}

#comments {
	> h3 {
		border-top: 1px dashed $brand-primary;
		padding-top: 15px;
		margin-top: 30px;
	}
	.comment-list {
		.avatar {
			margin: 0;
		}
	}
}


/*********************
02. LAYOUT & GRID STYLES
*********************/

@media only screen and (max-width: $screen-xs-max) {
	.container {
		padding: 0 20px;
	}
}

#content {
	background-size: 0 !important;
	padding-bottom: 40px;
	@media only screen and (min-width: $screen-sm-min) {
		background-size: auto !important;
	}
}

.home #content {
	padding-bottom: 0;
}


/*********************
03. LINK STYLES
*********************/

a {
	&:link {
		/*
	    this highlights links on iPhones/iPads.
	    so it basically works like the :hover selector
	    for mobile devices.
	    */
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0.3);
	}
	&:hover, &:focus {
		text-decoration: none;
		outline: 0;
	}
}

button:focus, .btn:focus, .btn:active:focus {
	outline: 0;
}


/*********************
04. H1, H2, H3, H4, H5, H6 STYLES
*********************/

.page_title {
	height: 110px;
	h1 {
		position: absolute;
		left: 12px;
		bottom: 15px;
		font: {
			family: $font-family-sans-serif;
			weight: 900;
		}
		text-transform: uppercase;
		letter-spacing: .05em;
		color: #fff;
		margin: 0;
	}
	@media only screen and (min-width: $screen-sm-min) {
		height: 220px;
		h1 {
			font: {
				size: 4.286em; // 60px
				weight: 700;
			}
		}
	}
}

h2 {
	margin-top: 40px;
}

@media only screen and (min-width: $screen-sm-min) {
	h2 {
		font-size: 3em; // 42px
	}
}


/*********************
05. HEADER STYLES
*********************/

#header {
	.navbar {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		background-color: #fff;
		border-color: $brand-primary;
		min-height: 0;
		margin: 0;
		.navbar-header {
			margin: 0;
			.navbar-toggle {
				border: none;
				padding: 10px 0;
				margin: 0;
				&:hover, &:focus {
					background: none;
				}
				.nav-text {
					float: left;
					font-weight: 700;
					margin-right: 10px;
				}
				.icon-wrap {
					float: left;
					.icon-bar {
						background-color: #000;
						border-radius: 50%;
						@include size(4px, 4px);
					}
				}
			}
			.navbar-brand {
				display: block;
				background-position: left top;
				background-repeat: no-repeat;
				@include retinize('logo_mobile', 'png', 66px, 25px);
				@include size(66px, 25px);
				margin-top: 8px;
			}
		}
		.search-form {
			position: relative;
			margin: 12px 15px;
			input[type=search] {
				position: absolute;
				right: 0;
				top: -4px;
				z-index: 3;
				width: 0;
				background: none;
				border: none;
				cursor: pointer;
				@include transition(width .5s cubic-bezier(0, 0.795, 0, 1));
				padding-right: 25px;
				&:focus {
					background-color: #fff;
					border-bottom: 1px solid #e1e1e1;
					width: 135px;
					z-index: 1;
					cursor: text;
				}
			}
			button {
				position: absolute;
				right: 0;
				top: 0;
				z-index: 2;
				border: none;
				border-radius: 0;
				background: url(../images/sprite.png) -154px top no-repeat;
				@include size(18px,18px);
				padding: 0;
				&:active {
					box-shadow: none;
				}
			}
		}
		#main-navbar-collapse {
			position: absolute;
			width: 100%;
			background-color: rgba(176,32,32,.9);
			border: none;
			box-shadow: none;
			margin: 0 -20px;
		}
	}

	@media only screen and (min-width: $screen-sm-min) {
		.navbar {
			position: relative;
			border: none;
			.navbar-header .navbar-brand {
				@include retinize('logo_tablet', 'png', 131px, 50px);
				@include size(131px, 50px);
				margin: 10px 20px 10px -5px;
			}
			.search-form {
				float: right;
				margin: 26px -5px 0 0;
				input[type=search]:focus {
					width: 570px;
				}
			}
			#main-navbar-collapse {
				position: static;
				background: none;
				width: auto;
				margin: 2px 0 0 12px;
			}
		}
	}

	@media only screen and (min-width: $screen-md-min) {
		.navbar {
			.navbar-header .navbar-brand {
				@include retinize('logo', 'png', 196px, 75px);
				@include size(196px, 75px);
			}
			.search-form {
				margin: 39px 0 0;
				input[type=search]:focus {
					width: 720px;
				}
			}
			#main-navbar-collapse {
				margin-top: 15px;
			}
		}
	}

	@media only screen and (min-width: $screen-lg-min) {
		.navbar {
			.search-form input[type=search]:focus {
				width: 750px;
			}
			#main-navbar-collapse {
				margin: 19px 0 0 90px;
			}
		}
	}

	@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    	only screen and (min--moz-device-pixel-ratio: 1.5),
    	only screen and (-o-min-device-pixel-ratio: 3/2),
    	only screen and (min-device-pixel-ratio: 1.5),
    	only screen and (min-resolution: 1.5dppx) {
    	.navbar .search-button button {
			background-image: url(../images/sprite@2x.png);
			background-size: 208px 44px;
		}
	}
}


/*********************
06. NAVIGATION STYLES
*********************/

/* Navigation principale */
#menu-menu-principal {
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: .05em;
	> li {
		> a {
			color: #fff;
			padding: 15px 20px;
		}
		&.open > a {
			background: none;
			padding-bottom: 0;
		}
		&.current-menu-parent > a {
			color: #000;
		}
		.dropdown-menu {
			padding-bottom: 13px;
			li {
				a {
					font-weight: 700;
					color: #bcbbbb;
					padding: 2px 20px;
				}
				&.current-menu-item a {
					color: #464444;
				}
			}
		}
	}
	@media only screen and (min-width: $screen-sm-min) {
		font: {
			family: $headings-font-family;
			size: 1.071em; // 15px
			style: italic;
		}
		text-transform: none;
		letter-spacing: 0;
		> li {
			position: static;
			> a {
				position: relative;
				color: #020202;
				padding: 24px 10px;
				&:hover, &:focus {
					color: $brand-primary;
				}
				&:after {
					content: "|";
					color: #000;
					position: absolute;
					right: -2px;
					top: 22px;
				}
			}
			&.current-menu-parent > a {
				color: $brand-primary;
			}
			.dropdown-menu {
				border: none;
				border-top: 2px solid $brand-primary;
				border-radius: 0;
				box-shadow: none;
				font: {
					family: $font-family-sans-serif;
					style: normal;
					size: 12px;
				}
				text-align: center;
				text-transform: uppercase;
				letter-spacing: .05em;
				width: 100%;
				padding: 10px 0;
				li {
					display: inline-block;
					a {
						color: #ff4e4e;
						&:hover, &:focus {
							background: none;
							color: #020202;
						}
					}
					&.current-menu-item a {
						color: #000;
					}
				}
			}
		}
	}
	@media only screen and (min-width: $screen-md-min) {
		font-size: 1.357em; // 19px
		> li {
			> a {
				padding: 24px 13px 36px;
			}
			.dropdown-menu {
				font-size: 14px;
			}
		}
	}
	@media only screen and (min-width: $screen-lg-min) {
		> li > a {
			padding: 20px 15px 36px;
			&:after {
				top: 18px;
			}
		}
	}
}

/* Navigation pied de page */
#menu-menu-secondaire-pied-de-page {
	list-style-type: none;
	font-size: .857em; // 12px
	padding: 0;
	margin: 0;
	li {
		display: inline-block;
		a {
			color: #fff;
			&:hover, &:focus {
				color: #ff4e4e;
			}
		}
		+ li {
			&:before {
				content: "-";
			}
			a {
				margin-left: 4px;
			}
		}
	}
	@media only screen and (min-width: $screen-sm-min) {
		font-size: 1em; // 14px;
	}
}

#menu-menu-principal-1, #menu-menu-principal-2 {
	list-style-type: none;
	font-size: .857em; // 12px
	padding: 0;
	margin: 0;
	> li {
		&.menu-accueil {
			display: none;
		}
		> a {
			text-transform: uppercase;
			letter-spacing: .05em;
			color: #fff;
		}
		.sub-menu {
			list-style-type: none;
			padding: 0;
			li {
				a {
					color: #ff4e4e;
				}
				&.active a, a:hover, a:focus {
					color: #fff;
				}
			}
		}
		+ li {
			margin-top: 20px;
		}
	}
	@media only screen and (min-width: $screen-sm-min) {
		font-size: 1em; // 14px;
		> li {
			display: inline-block;
			vertical-align: top;
			width: 22%;
			.sub-menu {
				margin-top: 20px;
			}
			+ li {
				margin: 0 0 0 3%;
			}
			&.menu-germaine-de-stael {
				margin: 0;
			}
		}
	}
	@media only screen and (min-width: $screen-lg-min) {
		> li {
			+ li {
				margin-left: 2%;
			}
			&.menu-germaine-de-stael {
				width: 24%;
			}
		}
	}
}

/* Pagination */
.pagination {
	border-top: 1px dashed $brand-primary;
	border-radius: 0;
	font: {
		size: .786em; // 11px
		weight: 700;
	}
	width: 100%;
	padding-top: 20px;
	margin: 15px 0 0;
	> li {
		display: inline-block;
		vertical-align: top;
		text-align: center;
		.page-numbers {
			display: block;
			border: 1px solid $brand-primary;
			border-radius: 50% !important;
			@include size (22px,22px);
			color: $brand-primary;
			padding: 2px;
			&.current, &:hover, &:focus {
				background-color: $brand-primary;
				border-color: $brand-primary;
				color: #fff;
			}
		}
		+ li {
			margin-left: 10px;
		}
	}
}


/*********************
07. CONTENT STYLES
*********************/

/*** CSS partagé **/
.accroche {
	font-weight: 700;
	color: $brand-primary;
	margin-bottom: 40px;
}

.backlink {
	text-transform: uppercase;
	letter-spacing: .05em;
	position: absolute;
	left: 20px;
	bottom: -20px;
	padding-left: 50px;
	&:before {
		content: "";
		background: url(../images/sprite.png) left top no-repeat;
		@include size(40px,40px);
		position: absolute;
		left: 0;
		top: -3px;
	}
	@media only screen and (min-width: $screen-sm-min) {
		left: 12px;
		bottom: -30px;
	}
	@media only screen and (min-width: $screen-md-min) {
		left: -12px;
		top: 2px;
	}
	@media only screen and (min-width: $screen-lg-min) {
		left: 0;
	}
	@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    	only screen and (min--moz-device-pixel-ratio: 1.5),
    	only screen and (-o-min-device-pixel-ratio: 3/2),
    	only screen and (min-device-pixel-ratio: 1.5),
    	only screen and (min-resolution: 1.5dppx) {
    	&:before {
			background-image: url(../images/sprite@2x.png);
			background-size: 208px 44px;
		}
	}
}

blockquote {
	position: relative;
	font: {
		family: $headings-font-family;
		style: italic;
		size: 1.286em; // 18px
	}
	color: #5d5d5d;
	border: none;
	padding: 0 5px;
	margin: 25px 0 20px;
	&:before {
		content: "";
		border-top: 4px solid $brand-primary;
		@include size(72px,4px);
		position: absolute;
		left: 50%;
		margin-left: -36px;
	}
	p {
		text-align: center;
		padding-top: 15px;
	}
	@media only screen and (min-width: $screen-sm-min) {
		font-size: 1.714em; // 24px
		padding: 0 20px;
		margin: 35px 0 30px;
	}
	@media only screen and (min-width: $screen-md-min) {
		padding: 0 60px;
	}
}

/* Slider & Banner */
#slider-wrapper, #banner, #slider-wrapper-bottom {
	padding: 0;
	margin-bottom: 20px;
	h3 {
		margin: 0;
	}
	p {
		text-align: left;
		margin: 0;
	}
	@media only screen and (min-width: $screen-sm-min) {
		width: auto;
		margin-bottom: 40px;
	}
	@media only screen and (min-width: $screen-md-min) {
		max-width: 1100px;
	}
}

/* Widget newsletter */
.mc4wp-form {
	background-color: #b02020;
	font-size: 1.571em; // 22px
	text-transform: uppercase;
	letter-spacing: .05em;
	color: #ff4e4e;
	padding: 10px 30px 20px;
	label {
		font-weight: 400;
		margin-bottom: 15px;
		color: #fff;
	}
	input {
		background: none;
		border: none;
		box-shadow: none;
		color: #fff;
		&[type=email] {
			float: left;
			border: 2px solid #fff;
			font-size: 14px;
			color: #fff;
			width: 185px;
			height: 34px;
			padding: 6px 12px;
			&:focus {
				border-color: #ff4e4e;
				outline: 0;
			}
			&::placeholder {
				color: #fff;
			}
		}
		&[type=submit] {
			font-weight: 700;
			font-size: 12px;
			vertical-align: .15em;
			margin-left: 5px;
			&:hover, &:focus {
				color: #ff4e4e;
				outline: 0;
			}
		}
	}
	.mc4wp-alert {
		font-size: .636em; // 14px
		text-transform: none;
		letter-spacing: 0;
		color: #fff;
		margin-top: 20px;
	}
	@media only screen and (min-width: $screen-sm-min) {
		padding: 10px 15px 20px;
		input[type=email] {
			width: 155px;
		}
	}
	@media only screen and (min-width: $screen-lg-min) {
		input[type=email] {
			width: 175px;
		}
	}
}

/* Widget Twitter */
#ctf {
	.ctf-header-name {
		color: #fff;
	}
}

/* --------------------------------------------- */

/*** Home **/
.home {
	#slider-wrapper .rsSBlock, #slider-wrapper .rsABlock  {
		left: 20px !important;
		bottom: 35px !important;
		width: auto !important;
		max-width: 280px;
		+ .rsSBlock, + .rsABlock {
			bottom: 5px !important;
		}
	}
	h2.block-title {
		font: {
			family: $font-family-sans-serif;
			size: .714em; // 10px
		}
		text-transform: uppercase;
		letter-spacing: .05em;
		margin: 0;
	}
	.recent_news {
		margin-bottom: 30px;
		article {
			border-top: 1px dashed $brand-primary;
			padding-top: 10px;
			margin-top: 10px;
			h3 {
				font: {
					family: $font-family-sans-serif;
					weight: 700;
					size: 1em; // 14px
				}
				line-height: $line-height-base;
				margin: 0 0 5px;
			}
			.accroche {
				font: {
					family: $headings-font-family;
					weight: 400;
					size: 1.143em; // 16px
					style: italic;
				}
				p {
					text-align: left;
				}
				margin: 0;
			}
		}
		.link-all-news {
			position: relative;
			display: block;
			font-size: .714em; // 10px
			text-transform: uppercase;
			letter-spacing: .05em;
			text-align: center;
			padding-top: 35px;
			&:before {
				content: "";
				background: url(../images/sprite.png) -94px top no-repeat;
				@include size(31px,30px);
				position: absolute;
				left: 50%;
				top: 0;
				margin-left: -15px;
			}
		}
	}
	.edito_content {
		font: {
			family: $headings-font-family;
			size: 1.143em; // 16px
			style: italic;
		}
		margin-top: 15px;
	}
	.widgets_home {
		margin-top: 30px;
		.widget {
			margin-bottom: 30px;
			&.widget_widgetagenda .event {
				position: relative;
				display: block;
				h3 {
					font: {
						family: $font-family-sans-serif;
						size: .714em; // 10px
					}
					text-transform: uppercase;
					letter-spacing: .05em;
					color: #fff;
					position: absolute;
					left: 15px;
					top: 15px;
					margin: 0;
				}
				.content_event {
					background-color: rgba(176,32,32,.6);
					position: absolute;
					left: 0;
					bottom: 0;
					width: 100%;
					text-align: center;
					padding: 5px 15px;
					time {
						font: {
							weight: 700;
							size: 1.143em; // 16px
						}
						color: #fff;
					}
				}
			}
		}
	}

	@media only screen and (min-width: $screen-sm-min) {
		#slider-wrapper .rsSBlock, #slider-wrapper .rsABlock {
			left: 12px !important;
			bottom: 70px !important;
			max-width: 62.5%;
			padding: 0 12px;
			+ .rsSBlock, + .rsABlock {
				bottom: 20px !important;
			}
		}
		h2.block-title {
			font-size: 1em; // 14px
		}
		.recent_news {
			article {
				margin: 15px 0 30px;
				h3 {
					font-size: 1.143em; // 16px
				}
			}
		}
		.edito_content {
			font-size: 1.286em; // 18px
			margin-top: 25px;
		}
		.widgets_home .widget {
			width: 33.3333%;
			&.widget_widgetagenda .event {
				h3 {
					font-size: 1em; // 14px;
				}
				.content_event {
					time {
						border-bottom: 1px solid #fff;
						padding-bottom: 5px;
					}
					.content_text {
						font-size: .857em; // 12px
						color: #fff;
						padding-top: 10px;
					}
				}
			}
		}
		#slider-wrapper-bottom {
			margin-bottom: 0;
		}
	}

	@media only screen and (min-width: $screen-md-min) {
		#slider-wrapper .rsSBlock, #slider-wrapper .rsABlock {
			left: 150px !important;
			max-width: 50%;
		}
	}

	@media only screen and (min-width: $screen-lg-min) {
		#slider-wrapper .rsSBlock, #slider-wrapper .rsABlock {
			left: 151px !important;
			bottom: 110px !important;
			+ .rsSBlock, + .rsABlock {
				bottom: 30px !important;
			}
		}
	}

	@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    	only screen and (min--moz-device-pixel-ratio: 1.5),
    	only screen and (-o-min-device-pixel-ratio: 3/2),
    	only screen and (min-device-pixel-ratio: 1.5),
    	only screen and (min-resolution: 1.5dppx) {
    	.recent_news .link-all-news:before {
			background-image: url(../images/sprite@2x.png);
			background-size: 208px 44px;
		}
	}
}

/* --------------------------------------------- */

/*** Œuvre **/
.single-oeuvre {
	#banner {
		background-size: cover !important;
		.page_title {
			height: 180px;
			h1 {
				font: {
					family: $headings-font-family;
					weight: 400;
					size: 1.714em; // 24px
					style: italic;
				}
				text-transform: none;
				letter-spacing: 0;
				bottom: 30px;
			}
		}
	}
	#content {
		position: relative;
		margin-bottom: 60px;
		.accroche {
			color: #000;
		}
		h2 {
			font-style: italic;
		}
		.notice {
			display: inline-block;
			background-color: #b02020;
			color: #fff;
			font-weight: 500;
			padding: 1px 5px;
			margin-top: 15px;
		}
	}

	@media only screen and (min-width: $screen-sm-min) {
		#banner .page_title {
			height: 440px;
			h1 {
				font-size: 4.286em; // 60px
			}
		}
		#content {
			margin-bottom: 70px;
			.topbutton, .printbutton {
				position: absolute;
				right: 260px;
				bottom: 84px;
				&:before {
					content: "";
					background: url(../images/sprite.png) -40px top no-repeat;
					@include size(21px,44px);
					position: absolute;
					left: 0;
					top: 0;
				}
			}
			.printbutton {
				right: 215px;
				bottom: 72px;
				&:before {
					background-position: -61px top;
					@include size(33px,32px);
				}
			}
			.notice {
				padding: 0 5px 2px;
			}
		}
	}

	@media only screen and (min-width: $screen-md-min) {
		#content {
			margin-bottom: 0;
			.topbutton {
				right: 220px;
			}
			.printbutton {
				right: 175px;
			}
		}
	}

	@media only screen and (min-width: $screen-lg-min) {
		#content {
			.topbutton {
				right: 250px;
			}
			.printbutton {
				right: 205px;
			}
		}
	}

	@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    	only screen and (min--moz-device-pixel-ratio: 1.5),
    	only screen and (-o-min-device-pixel-ratio: 3/2),
    	only screen and (min-device-pixel-ratio: 1.5),
    	only screen and (min-resolution: 1.5dppx) {
    	@media (min-width: $screen-sm-min) {
			&:before {
				background-image: url(../images/sprite@2x.png);
				background-size: 208px 44px;			}
    	}
	}
}

/* --------------------------------------------- */

/*** Liste des œuvres **/
.post-type-archive-oeuvre {
	.accroche + article {
		border-top: 1px dashed $brand-primary;
		padding-top: 15px;
	}
	article {
		border-bottom: 1px dashed $brand-primary;
		text-align: center;
		padding-bottom: 35px;
		margin-bottom: 15px;
		&:first-child {
			border-top: 1px dashed $brand-primary;
			padding-top: 15px;
		}
		.annee_oeuvre {
			font: {
				family: $headings-font-family;
				size: 2em; // 28px;
			}
			color: $brand-primary;
		}
		h2 {
			position: relative;
			font: {
				size: 1.143em; // 16px
				weight: 700;
				style: italic;
			}
			line-height: $line-height-base;
			margin: 0;
			a {
				color: #000;
				&:hover, &:focus {
					color: $brand-primary;
				}
				&:after {
					content: "";
					background: url(../images/sprite.png) -172px top no-repeat;
					@include size(36px,16px);
					position: absolute;
					left: 50%;
					margin-left: -18px;
					bottom: -20px;
				}
			}
		}
	}
	.pagination {
		border: none;
		padding: 0;
		margin: 0;
	}

	@media only screen and (min-width: $screen-md-min) {
		.accroche + article {
			padding-top: 25px;
		}
		article {
			text-align: left;
			padding-bottom: 25px;
			margin-bottom: 25px;
			&:first-child {
				padding-top: 25px;
			}
			.annee_oeuvre {
				display: inline-block;
				vertical-align: middle;
				font-size: 2.429em; // 34px
				width: 17%;
				margin-left: -40px;
			}
			h2 {
				display: inline-block;
				vertical-align: middle;
				width: 80%;
				a:after {
					left: auto;
					right: -50px;
					top: 50%;
					margin: -8px 0 0;
				}
			}
		}
	}

	@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    	only screen and (min--moz-device-pixel-ratio: 1.5),
    	only screen and (-o-min-device-pixel-ratio: 3/2),
    	only screen and (min-device-pixel-ratio: 1.5),
    	only screen and (min-resolution: 1.5dppx) {
    	article h2 a:after {
			background-image: url(../images/sprite@2x.png);
			background-size: 208px 44px;		}
	}
}

/* --------------------------------------------- */

/*** Liste des cahiers staëliens **/
.post-type-archive-cahier {
	.accroche + article {
		border-top: 1px dashed $brand-primary;
		padding-top: 15px;
	}
	article {
		border-bottom: 1px dashed $brand-primary;
		padding: 15px 0;
		&:first-child {
			border-top: 1px dashed $brand-primary;
		}
		.left-content {
			display: inline-block;
			vertical-align: top;
			.numero {
				font: {
					family: $headings-font-family;
					weight: 700;
					size: 1.429em; // 20px
				}
				text-align: center;
				background-color: $brand-primary;
				border-radius: 50%;
				@include size(36px,36px);
				color: #fff;
				padding-top: 6px;
			}
			.buy_link {
				display: inline-block;
				margin: 14px 0 0 2px;
				&:before {
					content: "";
					background: url(../images/sprite.png) -125px top no-repeat;
					@include size(29px,28px);
					display: block;
				}
			}
		}
		.entry-content {
			display: inline-block;
			vertical-align: top;
			width: 80%;
			margin-left: 5%;
			h2 {
				font: {
					family: $font-family-sans-serif;
					size: 1em; // 14px
					weight: 700;
				}
				line-height: $line-height-base;
				margin: 0;
				a {
					color: #000;
					&:hover, &:focus {
						color: $brand-primary;
					}
				}
			}
			.content {
				font: {
					family: $headings-font-family;
					size: 1.143em; // 16px
					style: italic;
				}
				p {
					text-align: left;
					margin: 20px 0 0;
				}
			}
		}
	}
	.pagination {
		border: none;
		padding: 0;
	}

	@media only screen and (min-width: $screen-sm-min) {
		article {
			position: relative;
			.left-content .buy_link {
				position: absolute;
				right: 0;
				top: 15px;
				margin: 0;
			}
			.entry-content {
				width: 75%;
				h2 {
					font-size: 1.143em; // 16px
				}
			}
		}
	}

	@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    	only screen and (min--moz-device-pixel-ratio: 1.5),
    	only screen and (-o-min-device-pixel-ratio: 3/2),
    	only screen and (min-device-pixel-ratio: 1.5),
    	only screen and (min-resolution: 1.5dppx) {
    	article .left-wrap .buy_link:after {
			background-image: url(../images/sprite@2x.png);
			background-size: 208px 44px;
		}
	}
}

/* --------------------------------------------- */

/*** Agenda **/
.post-type-archive-evenement {
	.accroche + article {
		border-top: 1px dashed $brand-primary;
		padding-top: 15px;
	}
	article {
		border-bottom: 1px dashed $brand-primary;
		padding-bottom: 15px;
		margin-bottom: 15px;
		&:first-child {
			border-top: 1px dashed $brand-primary;
			padding-top: 15px;
		}
		header .article_img {
			display: block;
		}
		.entry-content {
			a {
				color: #000;
				&:hover, &:focus {
					color: $brand-primary;
				}
			}
			time {
				display: block;
				font: {
					weight: 700;
					size: 1.143em; // 16px
				}
				margin-top: 10px;
			}
			p {
				margin: 0;
			}
		}
	}

	@media only screen and (min-width: $screen-sm-min) {
		article {
			overflow: hidden;
			header {
				float: left;
				margin-right: 20px;
				.article_img {
					max-width: 190px;
				}
			}
			.entry-content {
				overflow: hidden;
				a {
					display: block;
				}
				time {
					margin: 0;
				}
			}
		}
	}
}

/* --------------------------------------------- */

/*** Compte-rendu **/
.single-compte-rendu {
	article {
		h1, .h1 {
			font-size: 2em; // 28px
			margin: 0;
		}
		.author {
			font: {
				size: .714em; // 10px
				weight: 700;
			}
			text-transform: uppercase;
			letter-spacing: .05em;
			margin-top: 10px;
		}
		.editons {
			font-size: .714em; // 10px
			color: #7d7d7d;
			margin-top: 10px;
		}
		.article_img {
			margin: 20px 0;
		}
		.entry-content {
			h2 {
				font-style: italic;
			}
			sup {
				font-weight: 900;
			}
			.credibility-footnotes {
				margin-top: 50px;
				h4 {
					position: relative;
					font: {
						family: $font-family-sans-serif;
						size: .714em; // 10px
						weight: 700;
					}
					color: #7d7d7d;
					text-transform: uppercase;
					letter-spacing: .05em;
					&:before {
						content: "";
						background-color: $brand-primary;
						@include size(72px,4px);
						position: absolute;
						left: 0;
						top: -12px;
					}
				}
				ol {
					-moz-padding-start: 25px;
					-webkit-padding-start: 25px;
					li {
						position: relative;
						list-style-type: none;
						counter-increment: list;
						color: #7d7d7d;
						&:after {
						    content: counter(list) " |";
						    color: $brand-primary;
						    position: absolute;
						    left: -25px;
						    top: 0;
						}
						.return-link {
							vertical-align: -2px;
						}
					}
				}
			}
		}
	}
	.backlink {
		display: inline-block;
		position: relative;
		left: 0;
		top: 0;
		margin-top: 30px;
	}

	@media only screen and (min-width: $screen-sm-min) {
		article {
			.article_img {
				margin-top: 0;
			}
			.backlink {
				margin-top: 20px;
			}
			header {
				margin-bottom: 40px;
				h1, .h1 {
					font-size: 3em; // 42px
				}
				.author, .editons {
					font-size: 1em; // 14px
					margin-top: 20px;
				}
			}
			.entry-content {
				h2 {
					font-size: 2.286em; // 32px
				}
				.credibility-footnotes h4 {
					font-size: 1em; // 14px
				}
			}
		}
	}

	@media only screen and (min-width: $screen-md-min) {
		article .article_img {
			max-width: 195px;
		}
	}
}

/* --------------------------------------------- */

/*** Liste des comptes-rendus **/
.post-type-archive-compte-rendu {
	.accroche + article a {
		border-top: 1px dashed $brand-primary;
	}
	article {
		a {
			display: block;
			color: #000;
			border-bottom: 1px dashed $brand-primary;
			padding: 15px;
			.left-content {
				display: inline-block;
				vertical-align: top;
				.article_img {
					width: 50px;
				}
			}
			.entry-content {
				display: inline-block;
				vertical-align: top;
				width: 72%;
				margin-left: 5%;
				h2, .h2 {
					font: {
						family: $font-family-sans-serif;
						size: 1em; // 14px
						weight: 700;
					}
					line-height: $line-height-base;
					color: #000;
					margin: 0;
				}
				.author {
					font-size: .857em; // 12px
					margin-top: 5px;
				}
			}
			&:hover , &:focus {
				background-color: #b02020;
				color: #fff;
				h2, .h2 {
					color: #fff;
				}
			}
		}
		&:first-child a {
			border-top: 1px dashed $brand-primary;
		}
	}
	.pagination {
		border: none;
		padding: 0;
	}

	@media only screen and (min-width: $screen-sm-min) {
		article a {
			.entry-content {
				width: 80%;
				h2, .h2 {
					font-size: 1.143em; // 16px
				}
				.author {
					font-size: 1em; // 14px
				}
			}
		}
	}

	@media only screen and (min-width: $screen-md-min) {
		article a {
			padding: 15px 35px;
		}
	}
}

/* --------------------------------------------- */

/*** Actualité **/
.single-post {
	#content {
		position: relative;
		margin-bottom: 60px;
		article {
			h1 {
				font-size: 2em; // 28px
				margin-top: 0;
			}
			.article_img + h1 {
				margin-top: 20px;
			}
			.accroche {
				font: {
					family: $headings-font-family;
					style: italic;
					size: 1.143em; // 16px
					weight: 400;
				}
				color: #000;
				margin: 5px 0 0;
				p {
					text-align: left;
					margin: 0;
				}
			}
		}
	}

	@media only screen and (min-width: $screen-sm-min) {
		#content {
			margin-bottom: 70px;
			article h1 {
				font-size: 3em; // 42px
			}
		}
	}

	@media only screen and (min-width: $screen-md-min) {
		#content {
			margin-bottom: 0;
		}
	}
}

/* --------------------------------------------- */

/*** Liste des actualités **/
.blog {
	article {
		border-top: 1px dashed $brand-primary;
		padding-top: 15px;
		margin-top: 15px;
		&:first-child {
			border: none;
			padding: 0;
			margin: 0;
		}
		h2 {
			font: {
				family: $font-family-sans-serif;
				weight: 700;
				size: 1em; // 14px
			}
			line-height: $line-height-base;
			margin: 0;
		}
		.accroche {
			font: {
				family: $headings-font-family;
				style: italic;
				size: 1.143em; // 16px
				weight: 400;
			}
			color: #000;
			margin: 5px 0 0;
			p {
				text-align: left;
				margin: 0;
			}
		}
		.article_img {
			margin: 5px 0 10px;
		}
		.entry-summary p {
			text-align: left;
			margin-top: 5px;
		}
	}

	@media only screen and (min-width: $screen-sm-min) {
		article h2 {
			font-size: 1.143em; // 16px
		}
	}
}

/* --------------------------------------------- */

/*** Manifestations scientifiques **/
.journees-de-coppet, .colloques-de-coppet, .ateliers-jeunes-chercheurs {
	#banner {
		background-size: cover !important;
		.page_title {
			height: 180px;
		}
	}

	@media only screen and (min-width: $screen-sm-min) {
		#banner .page_title {
			height: 440px;
		}
	}
}

/* --------------------------------------------- */

/*** Chronologie **/
.chronologie {
	.chrono_periode {
		margin-top: 40px;
		p {
			margin: 0;
		}
		.chrono_date {
			font-weight: 700;
			color: $brand-primary;
		}
		.chrono_annees {
			font: {
				family: $headings-font-family;
				size: 2.429em; // 34px
			}
			text-align: center;
			.chrono_date {
				display: block;
				font-weight: 400;
				+ span {
					position: relative;
					&:before {
						content: "";
						background-color: $brand-primary;
						@include size(20px,2px);
						position: absolute;
						left: 50%;
						top: -3px;
						margin-left: -10px;
					}
				}
			}
		}
		figure {
			background: none;
			border: none;
			width: 100% !important;
			padding: 0;
			margin: 15px 0;
			img {
				display: inline-block;
				vertical-align: middle;
				border-radius: 50%;
				@include size(80px,80px);
			}
			figcaption {
				display: inline-block;
				vertical-align: middle;
				font-size: .857em; // 12px
				margin-left: 15px;
			}
		}
		&:first-child {
			margin: 0;
		}
	}

	@media only screen and (min-width: $screen-sm-min) {
		.chrono_periode {
			position: relative;
			figure {
				width: 250px !important;
				position: absolute;
				right: -275px;
				img {
					@include size(110px,110px);
				}
				figcaption {
					width: 125px;
				}
				&.j-necker {
					top: 170px;
				}
				&.s-necker {
					top: 290px;
				}
				&.e-stael {
					top: 410px;
				}
				&.narbonne {
					top: 530px;
				}
				&.constant {
					top: 340px;
				}
				&.bonaparte {
					top: 530px;
				}
				&.rocca {
					top: 860px;
				}
				&.a-stael {
					top: 560px;
				}
			}
		}
	}

	@media only screen and (min-width: $screen-md-min) {
		.chrono_periode {
			.chrono_annees {
				position: absolute;
				left: -120px;
				top: -5px;
				&:after {
					content: "";
					background-color: $brand-primary;
					@include size(2px, 72px);
					position: absolute;
					right: -24px;
					top: 10px;
				}
			}
			figure {
				width: 220px !important;
				right: -305px;
				figcaption {
					width: 95px;
				}
				&.constant {
					top: 180px;
				}
				&.bonaparte {
					top: 350px;
				}
				&.rocca {
					top: 690px;
				}
				&.a-stael {
					top: 430px;
				}
			}
		}
	}

	@media only screen and (min-width: $screen-lg-min) {
		.chrono_periode figure {
			width: 240px !important;
			right: -330px;
			figcaption {
				width: 115px;
			}
			&.constant {
				top: 230px;
			}
			&.bonaparte {
				top: 350px;
			}
			&.rocca {
				top: 630px;
			}
			&.a-stael {
				top: 370px;
			}
		}
	}
}

/* --------------------------------------------- */

/*** Adhésion **/
.adhesion {
	.accroche {
		color: #000;
	}
	.gform_wrapper {
		margin-top: 20px;
		li {
			margin-bottom: 30px;
			&.gsection {
				border-top: 1px dashed $brand-primary;
				margin-bottom: 20px;
			}
			&.gfield_price {
				margin-top: 30px;
				li {
					position: relative;
					margin: 0;
				}
			}
			.ginput_container_fileupload {
				margin-top: 5px;
				input[type=file] {
					border: none;
					padding: 0;
				}
			}
		}
		.gform_footer {
			position: relative;
			margin-top: 10px;
			input[type=submit] {
				background: none;
				border: none;
				color: #000;
				font-weight: 900;
				padding: 0 0 40px;
				&:hover, &:focus {
					color: $brand-primary;
				}
			}
			&:before {
				content: "";
				background: url(../images/paypal.png) left top no-repeat;
				@include size(156px,35px);
				position: absolute;
				left: 0;
				bottom: 0;
				z-index: -1;
			}
		}
	}

	@media only screen and (min-width: $screen-sm-min) {
		.gform_wrapper {
			.gfield_price {
				.ginput_container_radio, .ginput_container_total, .ginput_container_fileupload {
					float: none;
					width: 100%;
				}
			}
			.ginput_container_fileupload {
				float: none;
			}
			.gform_footer {
				float: left;
				input[type=submit] {
					padding: 0 165px 0 0;
				}
				&:before {
					left: 155px;
					bottom: -8px;
				}
			}
		}
	}
}

/* --------------------------------------------- */

/*** Page de résultats **/
.search-results {
	article {
		border-bottom: 1px dashed $brand-primary;
		padding: 15px 0;
		&:first-child {
			border-top: 1px dashed $brand-primary;
		}
		h2 {
			margin-top: 0;
		}
	}
	.pagination {
		border: none;
		padding: 0;
	}
}


/*********************
08. SIDEBARS & ASIDES
*********************/

.sidebar {
	> div {
		margin-top: 40px;
	}
	h3 {
		font: {
			family: $font-family-sans-serif;
			size: .714em; // 10px
		}
		text-transform: uppercase;
		letter-spacing: .05em;
	}
	.widget_jc_section_menu_widget #menu-menu-principal-1 {
		font: {
			size: 1em; // 14px;
			weight: 700;
		}
		li {
			border-bottom: 1px dashed $brand-primary;
			padding-bottom: 10px;
			margin: 0 0 10px;
			a {
				text-transform: none;
				letter-spacing: 0;
				color: #5d5d5d;
				&:hover, &:focus {
					color: $brand-primary;
				}
			}
			&:first-child {
				border-top: 1px dashed $brand-primary;
				padding-top: 10px;
				margin-top: 15px;
			}
			&.current-menu-item a {
				color: $brand-primary;
			}
		}
	}
	.widget_widgeteditions .editions {
		border-top: 1px dashed $brand-primary;
		font: {
			family: $headings-font-family;
			size: 1.143em; // 16px
		}
		color: #5d5d5d;
		padding-top: 10px;
		margin-top: 10px;
		p {
			text-align: left;
		}
	}
	.widget_text h3 + .textwidget {
		border-top: 1px dashed $brand-primary;
		color: #5d5d5d;
		padding-top: 10px;
		margin-top: 10px;
	}
	.widget_widgetsearchcahiers input[type=search] {
		border: 1px solid $brand-primary;
	}

	@media only screen and (min-width: $screen-sm-min) {
		> div:first-child {
			margin: 0;
		}
		h3 {
			font-size: 1em; // 14px
			margin-top: 5px;
		}
		.widget_jc_section_menu_widget #menu-menu-principal-1 {
			font-size: 1.143em; // 16px
			li {
				display: block;
				width: 100%;
			}
		}
		.PI_SimpleTwitterTweets h3 {
			margin: 0;
		}
	}
}

/*********************
09. FOOTER STYLES
*********************/

#footer {
	background-color: #000;
	color: #fff;
	padding: 20px 0;
	.widget_text p {
		text-align: left;
		.name {
			text-transform: uppercase;
			letter-spacing: .05em;
			font-size: .857em; // 12px
		}
	}

	@media only screen and (min-width: $screen-sm-min) {
		.widget_text p .name {
			font-size: 1em; // 14px
		}
		#menu-menu-secondaire-pied-de-page {
			margin-top: 20px;
		}
	}
}
